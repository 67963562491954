* {
  margin: 0;
  padding: 0;
}

.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 600px) {
  .overlay {
    background-color: rgba(0, 0, 0, 1);
  }
}

.link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  margin: 2px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  transition: all 0.3s ease;
}

.link:hover {
  background-color: #ad7231;
  color: black;
}

.signup {
  font-size: 1.2rem;
  border: 2px solid rgb(97, 228, 123, 0.6);
}

.signup:hover {
  background-color: rgb(97, 228, 123, 0.6);
  color: white;
}

.login {
  font-size: 1.2rem;
  border: 2px solid rgba(0, 119, 255, 0.6);
}

.login:hover {
  background-color: rgba(0, 119, 255, 0.6);
  color: white;
}
